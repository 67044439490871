@import url("https://fonts.googleapis.com/css?family=Mulish");



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#main {
  color: #fdfffc;
  background: #198754;
  font-family: 'Source Sans Pro', sans-serif;
}

*, *::before, *::after {
  box-sizing: inherit;
}


html body {
  padding: 0;
  margin: 0;
}

.App {
  height: 100vh;
  width: 100vw;
  max-width: 900px;
  padding: 1rem;
}

.sol-button {
  background-color: #512da8;
  width:110px;
  border: none;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: 'DM Sans', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  line-height: 48px;
  padding: 0 24px;
  border-radius: 4px;
}

element {
  --bg-color: #6E260E;
  --hover-bg: rgba(0,0,0,0.5);
  --selected-bg: #7DC3B4;

}
 
.ant-menu-light .ant-menu-item-selected{
  
  background-color:#7DC3B4;
}

.ant-menu-dark .ant-menu-item-selected{
  
  background-color:#7DC3B4;
}

.ant-menu-item .ant-menu-item-active{
  background-color: #7DC3B4;
}

.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover{
  background-color: rgba(0,0,0,0.5);
}

.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover{
  background-color: rgba(0,0,0,0.5);
}

.ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background-color: #195F50
}
